// extracted by mini-css-extract-plugin
export var textAccordion = "style-mod--text-accordion--1iA4J";
export var taTitle = "style-mod--ta-title--9I5y0";
export var content = "style-mod--content--3NApU";
export var accordionCard = "style-mod--accordion-card--3kzaG";
export var cardHeader = "style-mod--card-header--3Amne";
export var accordionIcon = "style-mod--accordion-icon--ZFMYo";
export var accordionIconOpen = "style-mod--accordion-icon-open--2vz2J";
export var accordionTitle = "style-mod--accordion-title--2CPoa";
export var accordionBody = "style-mod--accordion-body--1QFzD";
export var rating = "style-mod--rating--1Kjws";
export var clipStar = "style-mod--clip-star--1tJGk";
export var ratingDesc = "style-mod--rating-desc--1BSQY";