import React, { useState } from "react";
import { 
  Row, Col, Accordion, Card, Container 
} from "react-bootstrap";
import {
  textAccordion, content, taTitle,
  accordionCard, cardHeader, accordionTitle,
  accordionIcon, accordionBody, accordionIconOpen
} from "./style.mod.scss";
import RichText from "components/rich-text";
import {  ChevronDown } from "react-bootstrap-icons";

const TextAccordion = ({ data }) => {

  const { title , accordion } = data ;
  const [open, setOpen] = useState({});

  return (
    <div className={textAccordion}>
      <Container>
        <Row>
          <Col lg="6" sm="12">
            <p className={taTitle}>{title}</p>
            <div className={content}>
              <Accordion className="pointer">
                {
                  accordion?.map((_tab, index) => (
                    <Card key={index} className={accordionCard}>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={_tab._key}
                        className={cardHeader}
                        onClick={() => setOpen(open === _tab._key ? null : _tab._key)}
                      >
                        <p className={accordionTitle}>{_tab.title}</p>
                        <span className={`${accordionIcon} ${_tab._key !== open ? "" : accordionIconOpen}`}>
                          <ChevronDown/>
                        </span>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={_tab._key}>
                        <Card.Body className={accordionBody}>
                          <RichText blocks={_tab.accordionText} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))
                }
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TextAccordion;